'use client';

import React, { useEffect } from 'react';
import { useRouter } from 'next/navigation';

import auth from '../../../modules/auth/auth';
import authIframeMessageParser, {
    MESSAGE_REGISTER,
    MESSAGE_CONTACT,
    MESSAGE_SUCCESSFUL_SIGN_IN,
} from '../../../modules/auth/lib/authIframeMessageParser';
import checkPostMessageOriginIsSecure, { sendInsecureOriginSentryMessage } from '../../../helpers/checkPostMessageOriginIsSecure';

interface Props {
    onSubmitSuccess?: (() => void) | null;
    allowRegister?: boolean;
}
/**
 * Sign in wrapper that uses an iframe.
 * This listens to the response in the iframe and logs us in.
 */
const SignIn = ({ onSubmitSuccess = null, allowRegister = false }: Props) => {
    let { loginIframeUrl } = auth;
    const router = useRouter();

    useEffect(() => {
        const receiveMessageFromIframe = (e: { origin: any; data: any }) => {
            const { origin, data } = e;

            // Checks to make sure that a message is available, otherwise it's not from us.
            // Also stops multiple origin sentry calls.
            if (![MESSAGE_REGISTER, MESSAGE_CONTACT, MESSAGE_SUCCESSFUL_SIGN_IN].includes(data.message)) {
                return;
            }

            // Check that the origin of the message is from us.
            if (!checkPostMessageOriginIsSecure(origin)) {
                sendInsecureOriginSentryMessage('signin', origin);
                return;
            }

            authIframeMessageParser(data.message, data.value, onSubmitSuccess!, router);
        };

        window.addEventListener('message', receiveMessageFromIframe, false);
        return () => window.removeEventListener('message', receiveMessageFromIframe);
    }, []);

    // Iframe styles to apply. minHeight is set as 720px to account for the error block that may appear on the forgot password page.
    const style = {
        minHeight: '45rem',
        overflow: 'hidden',
        width: '100%',
        marginTop: 0,
    };

    if (allowRegister) {
        loginIframeUrl = `${loginIframeUrl}?allowRegister=true&titleType="login"`;
    }


    /**
     * We use the same url for both, but one iframes in and one opens a window with the url in.
     * Dev has some problems with session cookies and non matching urls for api + localhost.
     */
    return (
        <iframe
            style={style}
            src={loginIframeUrl}
            title="login form"
            frameBorder="0"
            scrolling="no"
            data-hj-allow-iframe
        />
    );
};

export default SignIn;
